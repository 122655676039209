<template>
  <div class="nutrition">
<!--    <swiper :options="swiperOption" class="swiperBlock flexItem110">-->
<!--      <swiper-item class="flexColStartRowCenter" v-for="(item,index) in banner" :key="index">-->
<!--        <div class="swiper-item__inner" :style="{backgroundImage:'url('+item.img+')'}"></div>-->
<!--      </swiper-item>-->
<!--    </swiper>-->
    <image-box :src="require('../../assets/image/nutrition/nutrition_1.jpg')" :height="649"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_2.jpg')" :height="790"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_3.jpg')" :height="894"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_4.jpg')" :height="976"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_5.jpg?v=1')" :height="1071"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_6.jpg')" :height="1340"></image-box>
    <image-box :src="require('../../assets/image/nutrition/nutrition_7.jpg')" :height="642"></image-box>
  </div>
</template>

<script>
  import ImageBox from "../../components/ImageBox/ImageBox";
  import {swiper, swiperItem} from "../../components/swiper";
  export default {
    name: "about",
    components: {
      "swiper": swiper,
      "swiper-item": swiperItem,
      "image-box":ImageBox
    },
    data() {
      return {
        swiperOption: {
          width:"100%",
          height:"600px",
          pageBtn: true,
          callback: () => {
          },
          pagination: {
            show: true,
            canClick:true
          },
          current: 0,
          itemsNum: 2,
          loop: true,
          loopTime: 5000,
          auto: true
        },
        // banner: [
        //   {
        //     img: require("../../assets/image/nutrition/nutrition_banner_1.jpg")
        //   },
        //   {
        //     img: require("../../assets/image/nutrition/nutrition_banner_2.jpg")
        //   },
        // ]
      }
    },
    created() {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "nutrition_pc";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "nutrition_mobile";
  }
</style>
